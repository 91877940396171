import React from 'react'
const HomeSection2 = () => {
  return (
    <>
      <div className='mx-4 lg:mx-10'>
        <h2 className='text-[#232c47] text-[1.7rem] md:text-[3rem] lg:text-[3.7rem] font-extrabold text-center pt-12 fontplayfairdisplay'>Insurance Companies Fear Us Because We’ve Been Winning Cases for Years</h2>
        <p className='text-[#687fa4] pt-4 text-center text-[18px] md:text-[24px] mb-3'> Millions Recovered for Our Valued Clients</p>
        <div className='flex items-center justify-center'>
          <iframe src="https://www.youtube.com/embed/ktWXGQ77S3w?si=GZnNqCfJaTKCZYmb" title="YouTube video player" className="w-full h-[20rem] md:w-[30rem] md:h-[25rem]" ></iframe>
        </div>
      </div>
    </>
  )
}

export default HomeSection2