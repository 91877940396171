import React from 'react';
import Form from '../../Components/Form/Form';
import img1 from '../../Assets/Hero/car-accidents-icon.png.webp';
import img2 from '../../Assets/Hero/employment-law-icon.png.webp';
import img3 from '../../Assets/Hero/personal-injury-icon.png.webp';
import img4 from '../../Assets/Hero/wrongful-death-icon.png.webp';

const HomeSection1 = () => {
  return (
    <>
      <div className='px-4 '>
        <div className='py-4 flex justify-center lg:items-center '>
          <Form />
        </div>
      </div>
      <div className='grid grid-cols-2 mx-auto  gap-y-4 lg:grid-cols-4 w-full justify-around'>
        <div className="flex justify-center cursor-pointer transition-all duration-300 ease-in-out px-3 py-5">
          <img src={img1} alt="Car Accident" />
        </div>
        <div className="flex justify-center cursor-pointer transition-all duration-300 ease-in-out px-3 py-5">
          <img src={img2} alt="Employment Law" />
        </div>
        <div className="flex justify-center cursor-pointer transition-all duration-300 ease-in-out px-3 py-5">
          <img src={img3} alt="Personal Injury" />
        </div>
        <div className="flex justify-center cursor-pointer transition-all duration-300 ease-in-out px-3 py-5">
          <img src={img4} alt="Wrongful Death" />
        </div>
      </div>
    </>
  );
}

export default HomeSection1;
