import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa'; // Import the green tick icon

function Form() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); // State to track successful submission

    useEffect(() => {
        let timer;
        if (errorMessage) {
            setShowError(true);
            timer = setTimeout(() => {
                setShowError(false);
                setErrorMessage('');
            }, 4000);
        }
        return () => clearTimeout(timer);
    }, [errorMessage]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!firstName || !lastName || !number || !email) {
            setErrorMessage('Please fill in all fields.');
            setIsLoading(false);
            return;
        }

        const formData = {
            first_name: firstName,
            last_name: lastName,
            number: number,
            email: email,
        };

        try {
            const response = await axios.post('https://backend.888hml.com/form.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            console.log(response.data);
            setIsLoading(false);
            setIsSuccess(true); // Set success state to true
            // Show success message for 4 seconds
            setTimeout(() => {
                setIsSuccess(false);
                setErrorMessage('');
                // Clear form data
                setFirstName('');
                setLastName('');
                setNumber('');
                setEmail('');
            }, 4000);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setErrorMessage('Failed to send your contact information. Please try again later.');
        }
    };

    return (
        <>
            {/* Form */}
            <div className='hidden md:block w-full lg:max-w-[450px] mx-4'>
                <div className='text-center p-4 bg-[#f05a1f] text-white rounded-tr-[2rem] '>
                    <h4 className='text-[21px] md:text-[24px] font-bold'>FREE CASE EVALUATION</h4>
                    <p className='text-[15px] md:text-[17px] font-semibold'>We've won over 5447 cases.</p>
                    <p className='text-[17px] md:text-[20px] font-semibold'>Fill out the form below and we'll review your case for FREE</p>
                </div>
                <div className='rounded-bl-[2rem] bg-white p-6 shadow-xl'>
                    <form className='flex flex-col gap-4 px-2' onSubmit={handleSubmit}>
                        <input type="text" placeholder='First Name' className='bg-[#f5f6fa] text-[#232c47] border p-2 rounded' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        <input type="text" placeholder='Last Name' className='bg-[#f5f6fa] text-[#232c47] border p-2 rounded' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        <input type="text" placeholder='Number' className='bg-[#f5f6fa] text-[#232c47] border p-2 rounded' value={number} onChange={(e) => setNumber(e.target.value)} />
                        <input type="email" placeholder='Email' className='bg-[#f5f6fa] text-[#232c47] border p-2 rounded' value={email} onChange={(e) => setEmail(e.target.value)} />
                        {showError && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        {isSuccess && (
                            <p className="text-green-500 mb-4">
                                <span className="inline-flex items-center">
                                    Your Email has been sent successfully <FaCheckCircle />
                                </span>
                            </p>
                        )}
                        <input type='submit' className='bg-[#fc7244] rounded-tl-full cursor-pointer rounded-br-full text-white font-medium text-[14px] md:text-[15px] py-4 px-6 ' value={isLoading ? 'Sending Email...' : 'Give me a Free Case Evaluation'} disabled={isLoading} />
                        {isLoading && (
                            <div className="fixed inset-0 flex items-center justify-center z-50">
                                <div className="animate-spin h-10 w-10 border-t-2 border-b-2 border-[#fc7244] rounded-full"></div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
            {/* Form ending*/}
        </>
    );
}

export default Form;
