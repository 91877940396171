import './index.css'
import React, { useEffect, useState }from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer.jsx';
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact.jsx'
import Faqs from './Pages/Faqs/Faqs.jsx'
import Practice from './Pages/Practice/Practice.jsx'
import WhatsAppLogo from './Components/Whatspplogo/WhasAppLogo';
import { BsChevronUp } from 'react-icons/bs';



function App() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {

      if (window.scrollY > 50) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {showScrollTop && (
        <div
          className="fixed bottom-8  z-[1000] hover:shadow-lg text-white  hover:scale-100 lg:right-14 right-[1.2rem] cursor-pointer hover:bg-[#ff9471] transition-all ease-out duration-300 p-2 bg-[#fc7244] shadow-md"
          onClick={scrollToTop}
        >
          <BsChevronUp size={18} className="" />
        </div>
      )}
      <WhatsAppLogo />
      <Navbar />
      <Routes>
        {/* Zain Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />

        {/* ibrahim routes */}
        <Route exact path="/practice" element={<Practice />} />

        {/* Awais routes */}
        <Route exact path="/faqs" element={<Faqs />} />
        <Route exact path="/contact" element={<Contact />} />

      </Routes>
      <Footer />
    </>
  );
}
export default App;

