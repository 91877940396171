import React from "react";
import lawyers from '../../Assets/GaryC.jpg'
import PracticePost from "./PracticePost";
import { Link } from "react-router-dom";

function Practice() {
  const screentop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div
        className="bg-cover bg-no-repeat bg-center h-[450px] text-white flex flex-col items-center justify-center"
        style={{ backgroundImage: `url(${lawyers})` }}
      >
        <h1 className='fontplayfairdisplay justify-center items-center text-[50px] font-bold'>Practice Areas</h1>
      </div>

      <div class="grid grid-cols-1 2xl:grid-cols-2 fontPoppins">
        <div class="2xl:col-span-2 text-xl 2xl:text-2xl 2xl:leading-10 mx-4 2xl:mx-14">
          <h1 className="lg:text-3xl text-2xl font-bold my-10">
          At HML Accidents Services , we focus exclusively on all areas of personal injury from Car accidents to ,motorcycle accidents, commercial trucking accidents, ride share accidents, slip and falls, workmans compensation, Dog bites and beyond 

          </h1>
          <p className="mb-12">
          Our legal practice is devoted to representing the rights of individuals who have been injured or harmed by the negligence of others.

          </p>
          <div>
            <PracticePost />
          </div>
          <div className="bg-[#fdba74] text-center text-black py-12 px-6 md:p-14 mb-[2rem]">
            <h1 className="text-4xl md:text-6xl font-semibold mb-6">
              Schedule a Free Consultation
            </h1>
            <p className="mb-6">
            For Decades HML Accident Services has been working with clients throughout the states of Florida ,Georgia , Texas and Wisconsin. Our team of attorneys is committed to providing effective and actionable legal advice and representation, and we provide our clients with access to their attorneys that you would never find at another large firm. To schedule a consultation with one of our lawyers, call us today or contact us online.
            </p>
            <Link to="/contact" onClick={screentop}>
            <button className="bg-[#fc7244] hover:bg-white hover:text-[#fc7244] text-white font-semibold py-3 px-6 rounded-tr-3xl rounded-bl-3xl">
              Schedule a Free Case Review
            </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Practice;
