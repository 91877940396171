import React from 'react';
import img1 from '../../Assets/about.jpeg';
import { Link } from "react-router-dom";

const HomeSection7 = () => {
    const screentop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    return (
        <div className='bg-[#f5fcfe] pb-10'>
            <div>
                <div className='flex space-y-12 mx-6 lg:mx-20 space-x-6 lg:grid lg:grid-cols-2 flex-col mt-12'>
                    <div className='fontpoppins pt-12'>
                        <h6 className='font-bold fontplayfairdisplay text-[#232c47] text-[25px]'> About Us </h6>
                        <p className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium'>
                            HML Accident Services is a unique firm that specializes in getting large settlements as well as verdicts. Unlike other firms, we give you the individualized attention that you won’t get from other larger firms.
                        </p>
                        <p className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium'>
                            What truly separates us from other firms is that we’ve been battling insurance companies for decades but most importantly, we used to work for the insurance companies.
                        </p>
                        <p className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium'>
                            Because of this, we have the knowledge and experience that other firms just don’t have. We know exactly what it takes for an insurance company to want to settle.
                        </p>
                    </div>
                    <div>
                        <img src={img1} alt="" className="w-full lg:w-full" />
                    </div>
                </div>
            </div>
            <div>
                <div className='flex mx-6 lg:mx-20 space-x-6 lg:grid lg:grid-cols-2 flex-col-reverse mt-12'>
                    <div className='flex justify-center items-center flex-col'>
                        <div className='flex  flex-col justify-center items-center'>
                            <h2 className='text-center text-[#131A2F] text-[32px] lg:text-[39px]  fontplayfairdisplay font-bold'>We're here to Help</h2>
                            <h4 className='text-[38px] text-orange-600  font-bold lg:text-[50px] fontoswald'>(888)448-6954</h4>
                            <Link to="/contact" onClick={screentop}>
                            <button className='text-[20px] w-[235px] max-w-full p-[20px] font-bold text-white rounded-tr-3xl rounded-bl-3xl bg-[#fc7244] mt-3'>Contact Us</button>
                            </Link>
                        </div>
                    </div>
                    <div className='fontpoppins '>
                        <h6 className='font-bold fontplayfairdisplay text-[#232c47] text-[25px] lg:pl-3'>Our triple Threat Approach</h6>
                        <ol className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium  lg:pl-20 list-decimal'>
                            <li className='pb-10'>
                                We get you set up with medical providers right away to start building up the evidence of your injuries, your pain, and your suffering. We get you into a rental car and help you get your car repaired or replaced.
                            </li>
                            <li className='pb-10'>
                                We gather all of the evidence we need to prove the case: photos, medical records and bills, witness statements, surveillance videos, and we get to know you as a person and how the accident affected you and your family.
                            </li>
                            <li>
                                We make the proper claims and guide you through the process.  We fight the insurance companies to make sure they pay you for your medical bills, lost earnings, pain, suffering, inconvenience, anxiety, emotional distress and loss of enjoyment of life, and any out-of-pocket expenses you had.
                            </li>
                        </ol>        
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HomeSection7;
