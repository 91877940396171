import React from "react";
import shatsapplogo from '../../Assets/call me.png';


const WhatsAppLogo = () => {
  const phoneNumber = "+18884486954"; // Replace with the desired phone number

  // Generate the WhatsApp chat link
  const generateCallLink = () => {
    const url2 = `tel:${phoneNumber}`;
    return url2;
  };
  return (

    <div>
      <div>
        <a href={generateCallLink()} target="_blank" rel="noopener noreferrer" >
          <img
            src={shatsapplogo}
            alt="WhatsApp"
            style={{ width: "50px", height: "50px" }}
            className="ml-[1.2rem] md:ml-[2.5rem] fixed bottom-6 cursor-pointer bg-green-500 rounded-[100%] p-[12px] shadow-black z-[1100]"
          />
        </a>
      </div>
    </div>

  );
};

export default WhatsAppLogo;
