import React from 'react'
import img1 from '../../Assets/result/4.jpeg'
function PracticePost() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full my-10">
        <div className="w-full flex items-center justify-center">
          <img
            src={img1}
            alt="Personal Injuries"
            className="w-full h-full lg:w-[20rem] lg:h-[13rem] rounded-tr-3xl rounded-bl-3xl"
          />
        </div>
        <div className="text-gray-700">
          <h2 className="text-2xl text-[#fc7244] font-bold mb-2">
            Catastrophic Personal Injury
          </h2>
          <p className="mb-2">
          When you or a loved one has suffered a severe injury, your primary goals are most likely acquiring the best health care possible to ensure a seamless healing process for a speedy recovery being able to return to work and resuming life as you’ve known it
          </p>
        </div>
      </div>
    </div>
  )
}

export default PracticePost
