import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa'; // Import the green tick icon

function Form() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); // State to track successful submission

    useEffect(() => {
        let timer;
        if (errorMessage) {
            setShowError(true);
            timer = setTimeout(() => {
                setShowError(false);
                setErrorMessage('');
            }, 4000);
        }
        return () => clearTimeout(timer);
    }, [errorMessage]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!name || !phone || !email || !message) {
            setErrorMessage('Please fill in all fields.');
            setIsLoading(false);
            return;
        }

        const formData = new URLSearchParams();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('message', message);

        try {
            const response = await axios.post('https://backend.888hml.com/contact.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            console.log(response.data);
            setIsLoading(false);
            setIsSuccess(true); // Set success state to true
            // Show success message for 4 seconds
            setTimeout(() => {
                setIsSuccess(false);
                setErrorMessage('');
                // Clear form data
                setName('');
                setPhone('');
                setEmail('');
                setMessage('');
            }, 4000);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setErrorMessage('Failed to send your contact information. Please try again later.');
        }
    };

    return (
        <>
            <div className='sm:col-span-1'>
                <h1 className='font-bold fontplayfairdisplay text-[20px] md:text-[40px] my-[30px] pl-[5px]'>Request a Free Case Review</h1>
                <form className='w-full border border-gray-300 rounded-lg mt-[30px] mb-[40px] mr-[80px] md:mr-0' style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)' }} onSubmit={handleSubmit}>
                    <div className="mt-4 mb-4">
                        <input type="text" id="name" name="name" className="mt-[20px] ml-[20px] w-[90%] md:w-[89%] py-[10px] border border-gray-300 px-3 placeholder-gray-400 text-gray-700 relative bg-[#f5f6fa] rounded text-base fontpoppins " style={{ fontSize: '15px', maxWidth: '100%' }} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className=" mb-4">
                        <input type="text" id="phone" name="phone" className=" ml-[20px] w-[90%] md:w-[89%] py-[10px] border border-gray-300 px-3 placeholder-gray-400 text-gray-700 relative bg-[#f5f6fa] rounded text-base fontpoppins " style={{ fontSize: '15px', maxWidth: '100%' }} placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <div className="mt-4 mb-4">
                        <input type="text" id="email" name="email" className="ml-[20px] w-[90%]   md:w-[89%] py-[10px] border border-gray-300 px-3 placeholder-gray-400 text-gray-700 relative bg-[#f5f6fa] rounded text-base fontpoppins " style={{ fontSize: '15px', maxWidth: '100%' }} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-4">
                        <textarea id="message" name="message" rows="6" className="ml-[20px] w-[90%] md:w-[89%] px-3 py-[20px] border border-gray-300 placeholder-gray-400 text-gray-700 relative bg-[#f5f6fa] rounded text-base md:text-lg shadow-sm fontpoppins" style={{ fontSize: '15px', maxWidth: '100%' }} placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    {showError && <p className="ml-[20px] text-red-500 mb-4">{errorMessage}</p>}
                    {isSuccess && (
                        <p className="ml-[20px] text-green-500 mb-4">
                            <span className="inline-flex items-center">
                                Your Email has been sent successfully <FaCheckCircle />
                            </span>
                        </p>
                    )}
                    <button className='bg-[#fc7244] ml-[20px] px-[45px] py-[15px] text-white   fontpoppins text-[17px] md:text-[22px] font-semibold rounded-lg rounded-tr-[30px] rounded-tl-[0px] rounded-br-[0px] rounded-bl-[30px] ' type="submit" disabled={isLoading}>{isLoading ? 'Sending Email...' : 'Submit'}</button>
                    {isLoading && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                            <div className="animate-spin h-10 w-10 border-t-2 border-b-2 border-[#fc7244] rounded-full"></div>
                        </div>
                    )}
                    <p className='fontpoppins w-[90%] md:w-[90%] text-black mx-[20px] md:mx-[20px] text-[14px] my-[30px] pb-[20px] text-justify'> <i>Contacting us through this form or the internet doesn't create attorney-client relationship. Confidential or time-sensitive information should not be sent through this form.</i> </p>
                </form>
            </div>
        </>
    );
}

export default Form;
