import React from "react";
// import SocialLinks from '../../Pages/Contact/SocialIcons'
import logo from "../../Assets/footer_logo.png"
import certificate from "../../Assets/certificate.png.webp"
import { FaFacebookF, FaGoogle, FaInstagram } from 'react-icons/fa';
import { Link } from "react-router-dom";

function Footer() {
  const screentop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="bg-[#222932] pt-[150px]   pb-[30px] text-white px-[2rem]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  fontpoppins justify-center gap-3">
          {/* Brand logo and social links */}
          <div className="flex flex-col items-center">
            <div>
              <Link to='/' onClick={screentop}>
                <img className="mb-3 w-[15rem] h-[15rem] md:w-full" src={logo} alt="" />
              </Link>
            </div>
            <div className='flex items-center gap-5 text-[20px] '>
              <a href="https://web.facebook.com/people/Accident-Attorney-Services/61557314196364/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <div className='p-[12px] rounded-[50%] bg-[#38424e] hover:bg-[#fc7244] cursor-pointer'>
                  <FaFacebookF />
                </div>
              </a>
              <a href="mailto:info@888hml.com" target="_blank" rel="noopener noreferrer">
                <div className='p-[12px] rounded-[50%] bg-[#38424e] hover:bg-[#fc7244] cursor-pointer'>
                  <FaGoogle />
                </div>
              </a>
              <a href="https://www.instagram.com/888hitmyline/?igsh=MzRlODBiNWFlZA%3D%3D" target="_blank" rel="noopener noreferrer">
                <div className='p-[12px] rounded-[50%] bg-[#38424e] hover:bg-[#fc7244] cursor-pointer'>
                  <FaInstagram />
                </div>
              </a>
            </div>
          </div>
          {/* Quick Links */}
          <div className="flex flex-col items-center mt-[3rem] md:mt-[0rem]">
            <div>
              <h1 className="text-[26px]  font-semibold fontplayfairdisplay">Quick Links</h1>
            </div>
            <div className="flex flex-col items-center mt-2 md:text-[17px] gap-3">
              <Link to="/" onClick={screentop}><div className="hover:text-[#fc7244] cursor-pointer">Home</div></Link>
              <Link to="/about" onClick={screentop}><div className="hover:text-[#fc7244] cursor-pointer">About</div></Link>
              <Link to="/practice" onClick={screentop}><div className="hover:text-[#fc7244] cursor-pointer">Practice Areas</div></Link>
              <Link to="/faqs" onClick={screentop}><div className="hover:text-[#fc7244] cursor-pointer">FAQs</div></Link>
              <Link to="/contact" onClick={screentop}><div className="hover:text-[#fc7244] cursor-pointer">Contact Us</div></Link>
            </div>
          </div>

          {/* Subscribe section */}
          <div className="flex flex-col  items-center md:text-left text-center md:items-start mt-[3rem] lg:mt-[0rem]  ">
            <h1 className="text-[26px] md:text-[26px]  font-bold fontplayfairdisplay">Subscribe For Newsletter</h1>
            <p className="fontpoppins  text-lg md:text-[15px]  my-2">News headlines delivered to your inbox. Sign up for free!</p>
            <img className="mx-auto" src={certificate} alt="" />
          </div>
        </div>
        <hr className="mt-[3rem] mx-auto mb-3 opacity-20" />
        <div className="text-center">
          <p className="text-xl font-thin mt-2 pb-[30px]">&copy; Copyright 2024, <Link to='/' onClick={screentop}><span className=" text-[#fc7244] font-bold cursor-pointer">HML Accident Services.</span></Link> All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
