import React from 'react'
import HomeSection1 from './HomeSection1'
import HomeSection2 from './HomeSection2'
import HomeSection4 from './HomeSection4'
import HomeSection5 from './HomeSection5'
import HomeSection6 from './HomeSection6'
import HomeSection7 from './HomeSection7'
import HomeSection8 from './HomeSection8'
import HomeSectioin9 from './HomeSectioin9'
import HomeSection10 from './HomeSection10'

function Home() {
  return (
    <>    
<HomeSection1 />
<HomeSection2 />
<HomeSection5 />
<HomeSection4 />
<HomeSection6 />
<HomeSection7 />
<HomeSection8 />
<HomeSectioin9 />
<HomeSection10 />
    </>
  )
}

export default Home
