import React from 'react'
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
const HomeSection6 = () => {

    const data = [
        {
            price: "$3,000,000",
            title: "Trip & Fall",
            subtitle: "Confidential",
        },
        {
            price: "$2,500,000",
            title: "Forklift",
            subtitle: "Settlement",
        },
        {
            price: "$1,500,000",
            title: "Wrongful Death",
            subtitle: "Settlement",
        },
        {
            price: "$1,000,000",
            title: "Slip & Fall",
            subtitle: "2023 Settlement",
        }, {
            price: "$1,000,000",
            title: "Truck Accident",
            subtitle: "2023 Settlement",
        }, {
            price: "$950,000",
            title: "Truck Accident",
            subtitle: "Settlement",
        }, {
            price: "$896,000",
            title: "Tow Truck Accident",
            subtitle: "Settlement",
        }, {
            price: "$750,000",
            title: "Car Accident",
            subtitle: "2023 Settlement",
        }, {
            price: "$750,000",
            title: "Truck Accident",
            subtitle: "2023 Settlement",
        },
        {
            price: "$580,000",
            title: "Car Accident",
            subtitle: " Settlement",
        }, {
            price: "$550,000",
            title: "Auto Accident",
            subtitle: "Settlement",
        },
    ]
    const responsive = {
        superLargeDesktop: {

            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 850, min: 550 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1
        },

    }
    return (
        <div className='w-full py-5 bg-[#fc8259] relative'>

            <h2 className='text-[46px] lg:text-[56px] font-bold fontplayfairdisplay text-center text-white'>Recent Results</h2>
            <div className="lg:px-12 ">
                <Carousel responsive={responsive}
                    autoPlay={true}
                    infinite={true}
                    arrows={true}
                >
                    {data.map((li, key) => (
                        <div className="    pt-6 ">
                            <div className="flex flex-col text-center text-white justify-center  items-center pt-7 text-[20px] font-bold   ">

                                <h1 className='text-[50px] font-extrabold fontoswald'>{li.price}</h1>
                                <div className='bg-[#00AEF0] w-[120px] h-[0.14rem] flex justify-center my-4 '></div>
                                <h2 className='fontpoppins  font-medium uppercase text-[26px]'>{li.title}</h2>
                                <p className='fontpoppins font-normal  text-[17px] pb-4'>{li.subtitle}</p>
                            </div>
                        </div>

                    )

                    )}

                </Carousel>

            </div>
        </div>
    )
}

export default HomeSection6