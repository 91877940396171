import React from 'react';
import logo from '../../Assets/certificate.png.webp';
import './Home.css'; 

const HomeSection5 = () => {
  return (
    <div className='relative w-[98%] mt-20 mb-16 rounded-bl-3xl rounded-tr-3xl mx-auto bg-[#fc7244] overflow-x-hidden'>
      <div className='flex py-12  text-white justify-center flex-col lg:flex-row space-x-3 w-[80%] items-center mx-auto'>
        <img src={logo} alt="" />
        <h2 className='text-[36px] lg:text-[56px] font-bold fontplayfairdisplay text-center'>
          We don't get paid until we win your case
        </h2>
        <div className="left-0 bg-[#ff9471] absolute w-full h-full opacity-[0.4]  animated-bg"></div>
      </div>
    </div>
  );
}

export default HomeSection5;
