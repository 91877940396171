import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import { BsChevronDown } from "react-icons/bs";
import "./navbar.css";
import { FaBars, FaHeadset, FaTimes } from "react-icons/fa";
const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [heading, setHeading] = useState(false);
  const navLinks = [
    { text: "Home", url: "/", exact: true },
    { text: "About Us", url: "/about", exact: false },
    { text: "Practice Areas", url: "/practice", exact: false },
    { text: "FAQ", url: "/faqs", exact: false },
  ];

  const screentop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <div className="relative bg-[#fff]">
        <div className="max-w-[1800px] mx-auto">
          <div className="lg:px-5">
            <div className="flex justify-center flex-col space-y-6 lg:space-y-0 lg:flex-row lg:justify-between relative ">
              <div className="lg:h-col lg:h-w-30 ">
                <div className="site-logo  relative mx-aut w-full  lg:bg-white lg:pr-[100px]">
                  <Link
                    to="/"
                    className="relative pb-2 z-[999] flex justify-center lg:justify-start items-center"
                  >
                    <img src={logo} alt="888 HML.com" className="w-full h-[10rem] lg:h-[120px] lg:w-[230px]" />
                  </Link>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center md:mb-0">
                <div className="mb-4 md:mb-0 mr-4">
                  <FaHeadset size={45}
                    className="text-[rgb(240,90,31)] mx-auto flex justify-center items-center"
                  />
                </div>
                <div className="text-center md:text-left">
                  <p className="font-poppins text-[16px] md:text-[19px]">
                    Call us for a{" "}
                    <b className="text-[#f05a1f] font-bold">FREE</b>{" "}
                    Consultation
                  </p>
                  <h2 className="text-[#f05a1f] text-[24px] md:text-[36px] font-bold mt-[-10px] fontoswald">
                    (888)448-6954
                  </h2>
                  <p className="text-[16px] md:text-[20px] text-[#3e6a98] pb-5 lg:pb-0">
                    No Fee until we win your Case
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fontpoppins sticky top-0 w-full  z-[999] text-white bg-black h-20 Georgian `}
      >
        <div
          className={`h-20 flex justify-between  lg:justify-center items-center font-medium text-md`}
        >
          <div className="ml-[1.2rem] block lg:hidden ">
            <Link to="/" onClick={screentop}>
              <div>
                <div className="text-orange-600 text-[20px] bold">
                  HML <span className="text-white ">ACCIDENT SERVICES</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="mr-[1.2rem] flex lg:hidden hover:text-orange-500 hover:drop-shadow-xl ">
            <div
              className="inline-flex items-center rounded-md hover:text-[#99702B] ease-in-out transition-all duration-200 text-lg font-medium "
              onClick={handleNav}
            >
              <FaBars
                size={22}
                className="text-white text-[24px] drop-shadow-md cursor-pointer hover:text-[#F86624] ease-in-out transition-all duration-200"
              />
            </div>
          </div>
          <div className="hidden lg:flex w-full h-full  justify-between">
            <ul className="md:ml-[2.5rem] flex items-center w-full hover:cursor-pointer">
              {navLinks.map((li, index) => (
                <li
                  key={index}
                  className="py-1 hover:text-[#F86624] drop-shadow-xl relative group pr-16"
                  onClick={screentop}
                >
                  <Link to={li.url} className="flex">
                    {li.text}
                    {li.dropdown ? (
                      <span className="text-md md:ml-2 mt-1 inline">
                        <BsChevronDown />
                      </span>
                    ) : null}
                  </Link>

                  {li.dropdown && (
                    <div className="absolute top-7 max-h-[40vh]  left-10 w-[200px]  hidden group-hover:block shadow-lg z-[999] bg-black/80">
                      <div className="pt-3 py-1">
                        <div className="w-4 h-4 absolute mt-5 rotate-45"></div>

                        <div className="py-4 px-3 flex flex-col">
                          {li.dropdown.map((mysublinks, subIndex) => (
                            <div
                              className="text-white hover:text-[#F86624]"
                              key={subIndex}
                            >
                              <Link to={mysublinks.url}>
                                <p className="text-md font-light hover:ml-2 ease-out transition-all duration-200">
                                  {mysublinks.text}
                                </p>
                                <p className="pt-5"></p>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <ul className="flex items-center hover:cursor-pointer">
              <Link to="/contact" onClick={screentop}><button className='w-[8rem] bg-[#fc7244] hover:bg-[#ff6735]  text-white fontpoppins text-[17px] rounded-[8px] mr-[2.5rem] py-2'>Contact US</button></Link>
            </ul>
          </div>
        </div>

        <div
          className={
            nav
              ? "Georgian bg-white absolute right-0 top-0 w-full text-center z-[999] rounded-sm shadow-md overflow-y-auto text-black "
              : "fixed right-[-100%] "
          }
        >
          <div className="sticky min-h-full bg-black text-white">
            <ul className="pt-4 px-6 bg-black text-white  f-h pb-3 ">
              <div className="flex justify-end md:pr-8 pt-4 ">
                <FaTimes
                  onClick={handleNav}
                  className="rounded mr-[-10px]  pt-[-03px] font-bold hover:bg-white"
                  size={24}
                />
              </div>
              <div className="mt-6">
                {navLinks.map((li, index) => (
                  <div key={index} className="relative">
                    <Link to={li.url} onClick={screentop}>
                      <li
                        className="cursor-pointer  py-4 pl-3 font-medium hover:rounded hover:bg-black/5 hover:text-orange-700"
                        onClick={handleNav}
                      >
                        {li.text}
                      </li>
                    </Link>
                    {li.dropdown && (
                      <BsChevronDown
                        className={`absolute right-0 top-0 mt-5 mr-8 text-white ${heading && "rotate-180"
                          }`}
                        onClick={() => setHeading(!heading)}
                      />
                    )}
                    {li.dropdown && heading && nav && (
                      <ul className="mt-2 ml-2 flex flex-col justify-center items-center max-h-[40vh] overflow-y-auto">
                        {li.dropdown.map((submenuItem, subIndex) => (
                          <Link to={submenuItem.url} key={subIndex}>
                            <li
                              className="text-white text-md ease-out transition-all duration-200 items-center gap-x-4 cursor-pointer p-5 rounded-md py-4 pl-3 hover:text-[#F86624]"
                              onClick={() => setNav(!nav)}
                            >
                              {submenuItem.text}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
                <Link to="/contact" onClick={screentop}>
                  <li
                    className="cursor-pointer  py-4 pl-3 font-medium hover:rounded hover:bg-black/5 hover:text-orange-700"
                    onClick={handleNav}
                  >
                    Contact Us
                  </li>
                </Link>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
