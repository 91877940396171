import React from 'react'

const HomeSection8 = () => {
  return (
    <div className='bg-white px-3 md:px-10'>
      <h2 className='text-[#131A2F] pb-8 pt-16 font-bold fontplayfairdisplay text-[37px] md:text-[45px] text-center '>    We Got Your Back! </h2>
      <p className='text-[17px] pb-4 font-medium leading-[1.75rem]  fontpoppins text-center text-[#232C47]'>
        We’re different.  We take the time to get to know you and to understand how the accident has affected your life.  We know that injuries don’t only cause physical pain, but they also cause mental suffering and can cause your family to suffer as well.  We make it our priority to learn your story and to fight to get you compensated for the damage that the accident has done to your life.  We win our cases because we work extremely hard on every case and we don’t stop fighting until we get you a full and fair settlement or jury verdict. We’re so confident in our ability to win your case that we only get paid once we win your case and we don’t get paid anything if we don’t win your case.
      </p>
    </div>
  )
}

export default HomeSection8