import React from 'react'
import bg from '../../Assets/contactUsNew.png';
import img1 from '../../Assets/locations/img1.jpeg';
import img2 from '../../Assets/locations/img2.jpeg';
import img3 from '../../Assets/locations/img3.jpeg';
import img4 from '../../Assets/locations/img4.jpeg';
import Form from './Form'
function Contact() {
  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-center h-[400px] text-white flex flex-col items-center justify-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <h1 className='fontplayfairdisplay text-[50px] font-bold'>Contact Us</h1>
      </div>
      <div className='grid grid-cols-1  md:grid-cols-2 md:gap-[3rem] ml-[1.2rem] mr-[1.2rem] md:mr-0 md:ml-[60px]'>
        <Form />
        <div className='md:col-span-1 '>
          <div>
            <h1 className='font-semibold fontpoppins text-[22px] md:text-[24px] mt-[50px]'>Principle office</h1>
            <div>
              <p className='fontpoppins text-[17px] md:text-[17px]'>2002 E 5th Avenue suite 107
                Tampa FL 33605</p>
            </div>

            <h1 className='font-semibold fontpoppins text-[22px] md:text-[24px] mt-[15px]'>we serve all of these places</h1>
            <div className='lg:grid lg:grid-cols-2'>
              <div className='flex items-center justify-center mt-[10px]'> <img src={img1} alt="" className="w-[70%]" /></div>
              <div className='flex items-center justify-center mt-[10px]'> <img src={img2} alt="" className="w-[70%]" /></div>
              <div className='flex items-center justify-center mt-[10px]'> <img src={img3} alt="" className="w-[70%]" /></div>
              <div className='flex items-center justify-center mt-[10px]'> <img src={img4} alt="" className="w-[70%]" /></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact