import React from 'react'
const HomeSection10 = () => {
    return (
        <div className=' bg-[#F5FCFE] mb-12 pt-12'>
            <h2 className='text-[#131a2f] text-center font-bold leading-[1.75rem] lg:leading-[1.125rem] text-[32px] md:text-[40px] lg:text-[50px] fontplayfairdisplay'> Call HML Accident services
            </h2> <h2 className='text-[#131a2f] mt-10 text-center font-bold leading-[1.75rem] lg:leading-[1.125rem]  md:text-[40px] lg:text-[50px] text-[32px] fontplayfairdisplay'> Today to Speak with an Attorney</h2>
            <div className='flex md:flex-row mt-16 flex-col mx-5 lg:mx-24 fontpoppins text-[#232C47] text-[17px] font-medium leading-[1.75rem]'>
                <div className='md:w-[50%] md:mt-[1.5rem]'>
                    <p>
                        Don’t put your case at risk by choosing the wrong firm. The last thing you want to do is choose a firm that’s not going to fight for you or simply accept any settlement just to get the case over and done with.
                        At HML Accident Services we’ve been helping people like you get large settlements for decades now and we have built a solid reputation for being able to do so.
                        <br />
                        <br />
                        We are committed to helping you get the largest settlements possible. We never accept a settlement until you’re happy with the amount first. We take a very aggressive approach when it comes to fighting for you. With our insightful and solution-oriented legal advice and decades of experience, you can rest assured knowing that we’ll get you the best settlement possible.
                    </p>
                </div>
                <div className='mt-[1.2rem] md:mt-0 md:w-[50%] md:ml-[2rem]'>
                    <iframe src="https://www.youtube.com/embed/s97UwoRkHuM?si=3McCKm9fwEMuNLMw" title="YouTube video player" className="w-full h-[22rem] md:h-[20rem] md:mt-[2rem]" ></iframe>
                </div>
            </div>
        </div >
    )
}

export default HomeSection10