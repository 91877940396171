import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa'; // Import the green tick icon

function Form1() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); // State to track successful submission

    useEffect(() => {
        let timer;
        if (errorMessage) {
            setShowError(true);
            timer = setTimeout(() => {
                setShowError(false);
                setErrorMessage('');
            }, 4000);
        }
        return () => clearTimeout(timer);
    }, [errorMessage]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!firstName || !lastName || !number || !email) {
            setErrorMessage('Please fill in all fields.');
            setIsLoading(false);
            return;
        }

        const formData = {
            first_name: firstName,
            last_name: lastName,
            number: number,
            email: email,
        };

        try {
            const response = await axios.post('https://backend.888hml.com/form.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            console.log(response.data);
            setIsLoading(false);
            setIsSuccess(true); // Set success state to true
            // Show success message for 4 seconds
            setTimeout(() => {
                setIsSuccess(false);
                setErrorMessage('');
                // Clear form data
                setFirstName('');
                setLastName('');
                setNumber('');
                setEmail('');
            }, 4000);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setErrorMessage('Failed to send your contact information. Please try again later.');
        }
    };

    return (
        <div className='mt-[50px] mx-[1.2rem] md:mx-[30px] mb-[40px] flex flex-col justify-center items-center border border-gray-300 rounded-lg rounded-tr-[30px] rounded-br-[0px] rounded-tl-[0px] rounded-bl-[30px] ' style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)' }}>
            <h2 className='fontplayfairdisplay text-[30px] md:text-[45px] font-bold mt-[40px]'>Contact Us Now</h2>
            <p className='fontpoppins font-semibold text-[17px] mb-[15px]'>For a free Evaluation</p>

            <div className="flex flex-col md:flex-row md:gap-5 mt-[15px] w-[90%]">
                <div className="w-full md:w-1/2 px-2 md:ml-[0%]">
                    <input type="text" placeholder="First Name" className="w-[90%] md:w-[100%] border border-gray-300 rounded-md px-3 py-2 bg-[#f5f6fa] " value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="w-full md:w-1/2 px-2 mt-[15px] md:mt-[0px]">
                    <input type="text" placeholder="Last Name" className="w-[90%] md:w-[100%] border border-gray-300 rounded-md px-3 py-2 bg-[#f5f6fa]" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:gap-5 mt-[15px] md:mt-[15px] w-[90%]">
                <div className="w-full md:w-1/2 px-2">
                    <input type="text" placeholder="Email" className="w-[90%] md:w-[100%] border border-gray-300 rounded-md px-3 py-2 bg-[#f5f6fa]" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="w-full md:w-1/2 px-2 mt-[15px] md:mt-[0px]">
                    <input type="text" placeholder="Phone" className="w-[90%] md:w-[100%] border border-gray-300 rounded-md px-3 py-2 bg-[#f5f6fa]" value={number} onChange={(e) => setNumber(e.target.value)} />
                </div>
            </div>

            {showError && <p className="text-red-500 mb-4">{errorMessage}</p>}
            {isSuccess && (
                <p className="text-green-500 mb-4">
                    <span className="inline-flex items-center">
                        Your Email has been sent successfully <FaCheckCircle />
                    </span>
                </p>
            )}


            <div className='mt-[30px] mb-[60px]'>
                <button
                    className='bg-[#fc7244] p-[20px] px-[15px] text-white hover:bg-white hover:text-[#fc7244] transition-colors duration-300 fontpoppins text-[15px] font-semibold rounded-lg rounded-tr-[30px] rounded-tl-[0px] rounded-br-[0px] rounded-bl-[30px]'
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? 'Sending Email...' : 'Get a FREE Case Evaluation'}
                </button>
                {isLoading && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="animate-spin h-10 w-10 border-t-2 border-b-2 border-[#fc7244] rounded-full"></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Form1;
