import React from 'react'
import img6 from '../../Assets/WhyUs/work-icon-02.png.webp';
import img2 from '../../Assets/WhyUs/work-icon-03.png.webp';
import img3 from '../../Assets/WhyUs/work-icon-04.png.webp';
import img4 from '../../Assets/WhyUs/work-icon-05.png.webp';
import img5 from '../../Assets/WhyUs/work-icon-06.png.webp';
import img7 from '../../Assets/WhyUs/work-icon-07.png.webp';
import img8 from '../../Assets/WhyUs/work-icon-08.png.webp';
import img9 from '../../Assets/WhyUs/work-icon-09.png.webp';
import img10 from '../../Assets/WhyUs/work-icon-10.png.webp';
const HomeSection4 = () => {
    const data = [
        {
            img: img6,
            title: "We'll Treat You Like Family",
            para: "We provide access to the best doctors. Don’t wait for appointments. The doctors won’t charge you up front or out of pocket."
        },
        {
            img: img2,
            title: "We'll Get You The Most Money Possible",
            para: "We only win if you win, so we have every incentive to get you as much money as possible. We don't charge any upfront fee, our success rate speaks for itself."
        },
        {
            img: img3,
            title: 'We Know Their Tricks',
            para: "Our network of attorneys know all the insurance companies sneaky tricks and are skillfully trained to spot and defeat the insurance companies tactics to ensure we get you the best end result possible."
        },
        {
            img: img4,
            title: '1 On 1 Access To Your Attorney',
            para: "With HML accident services were committed to effectively communicating with our clients ensuring a piece of mind from start to finish."
        },
        {
            img: img5,
            title: '24/7 Support',
            para: "Our support line is open 24/7 and we are always here to take your call."
        },
        {
            img: img7,
            title: 'Get Your Money Faster',
            para: "Once your case has been settled we'll do everything in our power to get you paid as quickly as possible, if you don't get paid, we don't get paid."
        },
        {
            img: img8,
            title: 'No Hidden Fees Means Larger Payouts For You ',
            para: "One thing people tend to overlook is hidden fees some firms charge which can take a chunk of your settlement. At HML Accident Services we have no hidden fees whatsoever!"
        },
        {
            img: img9,
            title: 'Making Sure Your Medical Expenses Are Covered',
            para: "We'll make sure that the responsible party covers all your medical bills. We'll also go as far as to negotiate unreasonable medical bills to make sure you retain as much of your settlement as possible."
        },
        {
            img: img10,
            title: 'We Know How To Fight',
            para: "We know you deserve to be compensated and we have years’ experience battling insurance companies to get our clients full and fair compensation."
        },


    ]
    return (
        <div className='mx-2 md:mx-10 lg:mx-20'>
            <h2 className='text-[34px] md:text-[48px] text-center font-bold text-[#1e273e] mt-6 fontplayfairdisplay '> Why Work With Us </h2>
            <div className='grid lg:grid-cols-2 justify-center gap-x-0 gap-y-6 md:gap-6 lg:gap-8 pt-6'>
                {data.map((ele, i) => (
                    <div key={i} className='bg-[#F5F6FA] px-2 md:px-8 py-6 shadow-lg border border-[##dce0e9] rounded-lg flex flex-col md:flex-row items-center'>
                        <div className='flex flex-col md:flex-row justify-center items-center flex-shrink-0'>
                            <img src={ele.img} alt="img" className='w-full h-auto' />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <h3 className='text-[#1E273E] font-bold text-[22px] md:text-[28px]'>{ele.title}</h3>
                            <p className='text-[18px] lg:text-[16px] text-[#444444]'>{ele.para}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HomeSection4