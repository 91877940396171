import React from 'react';
import { Link } from 'react-router-dom';

const HomeSectioin9 = () => {
  const screentop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section className="cta-w-content my-12 w-full" style={{ backgroundColor: '#f5f6fa' }}>
        <div className="mx-auto w-full lg:max-w-[1180px] ">
          <div className="wrapper">
            <div className="cta-outer">
              <div className="cta-inner">
                <h2 className="text-[30px] md:text-[42px]  lg:text-[50px] fontplayfairdisplay text-[#232C47] font-bold">Why It's Important To Contact An Attorney Right Away</h2>
                <div className="cta-content text-[#232C47] text-[17px] fontpoppins">
                  <p>Insurance companies are the enemy. They will do everything in their power to try to pay you as little as possible. We add a ton of value to your case by giving you access to immediate medical care, gathering critical evidence early on, guiding you through the claims process, and protecting you from the insurance companies and their case-killing strategies.</p>
                </div>
                <Link to="/contact" onClick={screentop}>
                  <button className='text-[20px] w-[235px] max-w-full p-[20px] font-bold text-white rounded-tr-3xl rounded-bl-3xl bg-[#fc7244] mt-3'>Contact Us</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // Your commented out JSX code...
  );
}

export default HomeSectioin9;
