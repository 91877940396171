import React from 'react'
import ffaqs from "../../Assets/Faqs.jpg"


function About() {
    return (
        <>
            <div
                className="bg-cover bg-no-repeat bg-center h-[400px]  text-white flex flex-col items-center justify-center"
                style={{ backgroundImage: `url(${ffaqs})` }}
            >
                <h1 className='mt-[10px] fontplayfairdisplay justify-center items-center text-[25px]  md:text-[40px] font-bold'>About Us</h1>
            </div>
            <div className='bg-[#f5fcfe] pb-10'>
                <div>
                    <div className='mx-6 lg:mx-20mt-12'>
                        <div className='fontpoppins'>
                            <p className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium'>
                                HML Accident Services is a unique firm that specializes in getting large settlements as well as verdicts. Unlike other firms, we give you the individualized attention that you won’t get from other larger firms.
                            </p>
                            <p className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium'>
                                What truly separates us from other firms is that we’ve been battling insurance companies for decades but most importantly, we used to work for the insurance companies.
                            </p>
                            <p className='text-[#232c47] text-[18px] mt-10 leading-[1.75rem] font-medium'>
                                Because of this, we have the knowledge and experience that other firms just don’t have. We know exactly what it takes for an insurance company to want to settle.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About
