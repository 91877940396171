
import React, { useState } from 'react'
import ffaqs from "../../Assets/Faqs.jpg"
import { CiCirclePlus } from "react-icons/ci";
import Form from './Form'
import Form1 from './Form1'

function Faqs() {
  const [openParagraph, setOpenParagraph] = useState(null);

  const toggleParagraph = (index) => {
    setOpenParagraph(index === openParagraph ? null : index);
  };

  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-center h-[400px]  text-white flex flex-col items-center justify-center"
        style={{ backgroundImage: `url(${ffaqs})` }}
      >
        <h1 className='mt-[10px] fontplayfairdisplay justify-center items-center text-[25px]  md:text-[40px] font-bold'>Frequently Asked Questions</h1>
      </div>

      {/* Toggle and Static Form */}

      <div className="grid sm:grid-cols-1 md:grid-cols-3 ml-[1.2rem] md:ml-[30px] md:mr-[30px] mt-[50px] mb-[40px]">
        {/* Toggle List */}

        <div className="col-span-2 mr-[20px]">
          <div className='flex flex-col gap-2'>
            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(2)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>Do I have to pay my deductible if someone else hits my car?
              </p>
              {openParagraph === 2 && (
                <p className="text-gray-600 bg-white hover:bg-gray-300 text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  If the other side accepts liability, you can go through the opposing insurance company to get your car repaired and won’t have to pay your deductible.  If the other side accepts liability, often times you can go through your own insurance company and they will waive the deductible and you won’t have to pay it.  If the other side does not accept liability, you can go through your own insurance company, pay your deductible, and get your deductible reimbursed once we get the other side to accept liability.
                </p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(3)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> Do I still have a case if I have prior injuries?
              </p>
              {openParagraph === 3 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Under California law, you are protected if you have prior or pre-existing injuries.  In fact, we will argue that you were more susceptible to injury than the ordinary person due to your prior or pre-existing injuries.  California law says that if someone is more susceptible to injury, they must still be compensated just as a normally healthy person would be.  Also, California law says that if you have a pre-existing condition that is made worse by an accident, you are entitled to compensation for the effect on that condition.  So if you have pre-existing back pain and get into a car accident or have a slip and fall accident and your pain gets worse, you are entitled to compensation for that. </p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(4)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> Who will be handling my case?
              </p>
              {openParagraph === 4 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Experienced attorneys will be handling your case from start to finish.  Unlike many firms, from day one you will speak to and work with an attorney.  Our personal injury attorneys will walk you through the entire claims process, will help you get the medical care you need and deserve, will help you get your car repaired, and will help negotiate a full and fair settlement for you.</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(5)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> Does my attorney decide how much I settle for?
              </p>
              {openParagraph === 5 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  No.  It is your case and you make the final decision about whether or not to settle your case.  Our law firm can give you suggestions based on past settlements and jury verdicts and tell you whether you are receiving full and fair compensation for your case.  However, our law firm will never settle your case without your consent.</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(6)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] font-bold text-[17px] hover:bg-gray-300 fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> If I get an attorney, do I have to go to court?
              </p>
              {openParagraph === 6 && (
                <p className="text-gray-600 bg-white text-[17px] fontpoppins px-[20px] py-[15px] text-justify">
                  Over 95% of cases settle without going to trial.  Most cases settle even before a lawsuit is filed.  However, when the insurance company is being unreasonable and is making low-ball offers, we file a lawsuit to increase the pressure and get you the best result possible.  Even after filing a lawsuit, the likelihood of going to court is minimal. </p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(7)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> What happens if I get hit by someone with no insurance?
              </p>
              {openParagraph === 7 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Unfortunately, the number of drivers in California that are driving without insurance is on the rise.  Hopefully you have uninsured motorist coverage (“UM coverage”).  If you have UM coverage, you can make a claim against your own insurance company and your insurance company will act as the other driver’s insurance.  This will allow you to make a claim and get compensated for your medical expenses, property damage, pain and suffering, etc.  But don’t worry, making a claim against your own insurance company through your UM coverage will not raise your insurance rates</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(8)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>Should I call the police at the scene of an accident?
              </p>
              {openParagraph === 8 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Yes, you should.  The police won’t always come to the scene of an accident unless injuries are reported.  Having the police come out to the scene will help lock down who is at fault.  The police will usually prepare a Traffic Collision Report and will take your statement, so it is critical that you provide an accurate statement and make sure that the police officers speak to all witnesses to the accident.</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(9)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px]  fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>How do insurance companies try to reduce the value of my case?
              </p>
              {openParagraph === 9 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Insurance companies are notorious for trying to reduce the value of your case by making the following arguments: you didn’t seek medical care soon enough after the accident or injury; you had large gaps between your medical appointments; you complained about certain body parts in the beginning of your medical treatment and then different body parts later on; you didn’t follow your doctor’s recommendations; you didn’t complete your medical treatment; and you had pre-existing injuries.</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(10)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px]  fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>Where is the best place to go right after an accident or injury?
              </p>
              {openParagraph === 10 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Depending on the severity of your injury, you should seek immediate medical attention at the nearest emergency room.  If your injury is not as severe or you don’t want to go to an emergency room, you can go to an urgent care or to your primary care doctor.  Either way, it is critical that you seek immediate medical attention on the day of the accident or injury, or the day after.</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(11)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px]  fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>What evidence should I gather at the scene of a slip and fall/trip and fall?
              </p>
              {openParagraph === 11 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  To start preserving critical evidence right away, you should get the following: photos and video of what you fell on; the names, addresses, and phone numbers of any witnesses to your fall; photos of your injuries; make an incident report and get a copy of it; and get the names and job titles of any store employees that you spoke with or that witnessed your fall.</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(12)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px]  fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> What evidence should I gather at the scene of an accident?
              </p>
              {openParagraph === 12 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  To start building your case from day one, you should get the following: insurance information for the other drivers involved in the accident; photos of your vehicle and the other vehicles involved in the accident; names, addresses and phone numbers of anyone that witnessed the accident; photos of the intersection or the roadway where the accident occurred; photos of the license plates of the vehicles involved in the accident; and photos of your injuries</p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(13)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px]  fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>Should I talk to the insurance companies after a car accident?
              </p>
              {openParagraph === 13 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Do not, under any circumstances, speak to the other driver’s insurance company.  Let your attorneys take care of that.  The other driver’s insurance company is not on your side.  Anything you say to them can and will be used against you.  Consult an experienced attorney that can handle the communications with the opposing insurance company.  With respect to your own insurance company, under your insurance policy you are required to speak to them and to cooperate with them. </p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(14)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px]  fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span>What happens if I get injured but don’t have health insurance?
              </p>
              {openParagraph === 14 && (
                <p className="text-gray-600 bg-white text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  Fortunately, our law firm has a network of excellent doctors and medical specialists that can provide you with medical care and won’t charge anything up front or out-of-pocket.  These top-notch doctors and medical providers will enter into an agreement with you and our law firm to wait to collect payment until your case is resolved.  This will allow you to get the medical care you need right away, without having to worry about paying co-pays or out-of-pocket for your medical treatment.                </p>
              )}
            </div>

            <div className="mb-1 ">
              <p onClick={() => toggleParagraph(15)} className="cursor-pointer flex flex-cols border border-gray-300 py-[8px] hover:bg-gray-300 font-bold text-[17px] fontplayfairdisplay">
                <span className='mt-[5px] text-[#fc7244] ml-[6px] mr-[6px]'><CiCirclePlus /></span> Do I have to pay upfront for your law firm’s services?
              </p>
              {openParagraph === 15 && (
                <p className="text-gray-600 bg-white  text-[13px] fontpoppins px-[20px] py-[15px] text-justify">
                  No.  You pay nothing upfront or out-of-pocket for our services.  We work on what is called a contingency fee basis.  This means that we only get paid once we win your case.  We get a percentage of what we get for you.  If for some reason there is no recovery in your case, we don’t charge you a penny!  There is no risk for you to choose our law firm because we do not charge anything unless and until we win your case.  We don’t even charge for the initial consultation like many firms do.</p>
              )}
            </div>

          </div>

        </div>

        <div className="col-span-1">
              <Form/>
        </div>

        {/* Toggle and Static Form */}
      </div>
      {/* ************************************************************************************************************************************************************* */}

      <Form1/>

    </>
  )
}
export default Faqs;

